import React from "react";
import Layout from "../../../layouts";
import Seo from "../../../components/Seo";
import IntroBanner from "../../../components/IntroBannerRevamp";
import Banner from "../../../images/services/ai/ai-banner.png";
import ContrastSection from "../../../components/ContrastSection";
import CasestudyImg from "../../../images/services/ai/casestudy-img.png";

import {
  Row,
  Col,
  Container,
} from "reactstrap";

const Fintech = () => {
  return (
    <Layout bodyClass="case-study-page">
      <Seo
        keyword="Data & AI Enablement"
        title="Data & AI Enablement"
        description="Innovation Partner - IoT, FinTech, Mobile Apps & Software Development, Cloud Engineering | iTelaSoft"
      />

      <div className="casestudy-banner" id="up">
        <div className="container">
          <div className="row main-page">
              <div className="col-lg-12">
                <h4 className="page-title">
                  How <span className="yellow-larger"><u>Artificial</u></span> 
                  <span className="white-larger"> Intelligence</span> Led to a <span className="white-larger"> 40% </span> 
                  <span className="yellow-larger"><u>Reduction</u></span> IN RESPONSE <span className="white-larger"> TIME</span> For  <span className="yellow-larger"><u>Mortgage</u></span> <span className="white-larger"> Brokers</span></h4>
              </div>
          </div>
        </div>
      </div>

      <section className="light-grey-section">
        <Container>
          <div className="title title-1">Introduction</div>
          <div className="white-card mt-2">
          In any professional services organisation that is driven by mass scale business leads, assigning a lead from a potential customer to the most matching knowledge worker is an ongoing challenge. Success of the business depends heavily on how quickly and insightfully a lead is handed over to the person, with best matching experience and capability, to handle that customer’s unique situation.
          </div>
        </Container>
      </section>

      <section>
        <Container className="mt-4">
          <div className="title title-1">THE PROBLEM</div>
          <div className="sub-title mt-2 grey-text">
            iTelaSoft has been working in the real estate mortgage domain for many years with multiple customers. Mortgage broker companies receive leads for home loans from multiple channels. These leads are directed to a pool of brokers in the company to serve each customer. Characteristics of each lead depends on the personnel circumstances of the customer. This assignment is usually done either by manual inspection by a “senior broker” (manager) or some unstructured method.
          </div>
          <div className="sub-title bold-text mt-2 mb-sm-2 mb-0">
            <h4><b>Those traditional methods cause multiple issues including,</b></h4>
          </div>
          <div className="mb-4">
            <Row className="mt-2">
              <Col lg={5}>
                <div className="yellow-card">
                  Lengthy customer wait-times during the “warm lead” phase, and eventually losing the customer.
                </div>
              </Col>
              {/* <Col  lg={6} className="d-flex align-items-center">
                <div className="dash-line">
                </div>
              </Col> */}
            </Row>
            <Row className="mt-2">
              <Col lg={2}>
              </Col>
              <Col lg={5}>
                <div className="mid-yellow-card ">
                  Less than satisfactory matchmaking of the lead and broker, often with experience and speciality mismatch. This leads into poor customer satisfaction.
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg={4}>
              </Col>
              <Col lg={5}>
                <div className="light-yellow-card ">
                  Slower workflow, reducing the optimal usage of broker pool.
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg={7}>
              </Col>
              <Col lg={5}>
                <div className="yellow-card">
                <h5><b>Addressing performance issues</b></h5>
                  High cost of expert workers and use of them in repetitive, mundane tasks.
                </div>
              </Col>
            </Row>
            <div className="mt-2"> 
              <h6>
                <b>
                  Above factors result in lower-than-average performance of the broker team, and eventually hurting the revenue and growth of business.
                </b>
              </h6>
            </div>
          </div>
        </Container>
      </section>

      <section className="light-grey-section">
        <Container>
          <div className="title title-1">Challenge</div>
          <div className="white-card mt-3">
            iTelaSoft engaged in building a CRM platform which also provided a solution for the above-mentioned problem. Our early assessment indicated that, quick engagement of an intelligent activity (usually by an experienced senior broker) and the thought process plays a major role in accelerating the flow by assigning the right broker for a given lead. Our approach was to learn and mimic this thought process by an artificial decision model.
          </div>
          <Row>
            <Col sm={6} className="col-12 mt-2">
              <div className="white-card">
                iTelaSoft created a decisioning engine for analysing the nature of a new lead and automatically assign to a matching broker. The decisioning model considered many parameters both representing the characteristics of the new lead, as well as characteristics plus behaviour of brokers. 
              </div>
            </Col>
            <Col sm={6} className="col-12 mt-2">
              <div className="white-card">
                Once the model was trained with enough real-world experiences, the decisioning engine was able to automatically and quickly locate an appropriate mortgage broker for a lead, almost in real time. It was like a subject matter expert constantly watching the lead pipeline and picking the best broker to deal with the lead!
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container className="mt-5">
          <div className="title title-1">The Impact</div>
          <div className="grey-text mt-2">
            With the help of Automated Lead Distribution System, multiple mortgage broker businesses were able to make notable positive changes in their operations.
          </div>
          <Row className="mt-1">
            <Col lg={5} md={6} className="mt-2">
              <div className="case-study-img"></div>
            </Col>
            <Col lg={7} md={6} className="pl-lg-5 mt-2">
              <div className="grey-card">
                Average first response time to a potential customer was reduced by 40%.
              </div>
              <div className="grey-card mt-2">
                Over 30% increase in number of leads handled by a broker.
              </div>
              <div className="grey-card mt-2">
                Significant reduction of cross allocated leads and opportunities missed in the middle of workflow.
              </div>
              <div className="grey-card mt-2">
                Reduced over allocation and better use of the broker pool.
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container className="mt-4">
          <div className="title title-1">Under the Hood</div>
          <Row>
            <Col lg={5} md={6} className="mt-2">
            <div className="sub-title light-yellow-card">
              In technical terms, core part of the decision engine was based on a multi-class classifier. iTelaSoft used it’s own “Auto-Learning Framework” to accelerate the implementation time by automatically analyse data, and infer the best machine learning algorithm, plus fine tuning.
            </div>
            </Col>
            <Col lg={7} md={6} className="mt-2">
            <div className="sub-title light-yellow-card">
              Practically, a problem like this is not solvable only using a machine learning model trained with past experiences. There are other real time parameters to consider such as a broker being not available or overloaded at a given time. So, the decisioning engine contained a rule-based decision tree in addition to the pre-trained model.
            </div>
            </Col>
          </Row>
        </Container>
        <Container className="mt-4">
          <div className="title title-1 text-end">Where else is this applicable?</div>
          <Row>
            <Col lg={8} md={7} className="mt-2">
              <div className="sub-title mid-yellow-card">
                Overall, the same solution can be applied to any use case, where a particular 
                scenario should be categorized, and handed over to someone for execution. 
                Some examples are, support case triaging, referral management, assessment 
                marking, claims handling etc.
              </div>
            </Col>
            <Col lg={4} md={5} className="mt-2">
              <div className="sub-title mid-yellow-card">
                It is all about training a system with human 
                expertise in a repetitive activity and 
                combining with real world rules to make 
                quick decisions
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="black-section mt-5">
        <Container className="py-2">
          <div>
            <div className="title title-1">
              Want <span className="yellow-larger">AI</span> to <span className="yellow-larger">improve</span> your <span className="yellow-larger">business</span> <span className="white-larger">outcomes</span>?
            </div>
            
            <div className="sub-title mt-1">
              We’ve been designing and implementing cutting edge AI and machine learning solutions for over a decade.
            </div>
          </div>

          <div className="mt-4 contact-section">
            <div className="contact-txt">
              If you want to know what’s possible for your business,
            </div>
            <a className="contact-link" href="/contact/">Please contact our team</a>
          </div>
        </Container>
      </section>
      


    </Layout>
  );
};

export default Fintech;
